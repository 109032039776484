import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries } from '../helpers/styles';

export interface ISectionItemProps {
  children?: ReactNode;
  padding?: string;
}

const StyledSectionItem = styled.div<ISectionItemProps>`
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;

  ${mediaQueries(
    null,
    'sl'
  )(css`
    padding: 2vw;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    // @ts-ignore
    padding: ${({ padding }) => padding || '4vw'};
  `)}
`;

const SectionItem: React.FC<ISectionItemProps> = ({ children, padding }) => {
  return <StyledSectionItem padding={padding}>{children}</StyledSectionItem>;
};

export default SectionItem;

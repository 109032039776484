import React, { useEffect, useState } from 'react';
import whiteLogo from '../assets/white_logo.png';
import whiteLeaves from '../assets/white_leaves.png';
import styled, { css } from 'styled-components';
import { mediaQueries } from '../helpers/styles';
import Fade from 'react-reveal/Fade';
import Separator from './Separator';
import { isMobile } from 'react-device-detect';
import { Colors } from '../constants/colors';
import Button from './Button';

let bgvideo;
let bgvideoPhoto;

if (isMobile) {
  bgvideo = require('../assets/bgvideo_m.mp4');
  bgvideoPhoto = require('../assets/bgvideo_m.jpg');
} else {
  bgvideo = require('../assets/bgvideo.mp4');
  bgvideoPhoto = require('../assets/bgvideo.jpg');
}

const StyledVideoCover = styled.div`
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100%;
  position: relative;

  background-image: linear-gradient(
    to left bottom,
    ${Colors.PRIMARY_GREEN_LIGHTER},
    ${Colors.PRIMARY_GREEN_DARKER}
  );

  video,
  img.bgplaceholder {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const StyledBackDrop = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  font-size: calc(20px + 0.5vw);
  line-height: calc(22px + 2vw);
  color: #fff;

  ${mediaQueries(
    null,
    'ml'
  )(css`
    font-weight: 200;
  `)}
  ${mediaQueries(
    'ml',
    null
  )(css`
    font-weight: 100;
  `)}
`;

const StyledLogo = styled.div`
  ${mediaQueries(
    null,
    'sl'
  )(css`
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 3vh;
    padding: 0 calc(6vw + env(safe-area-inset-right));
  `)}

  ${mediaQueries(
    'sl',
    null
  )(css`
    position: absolute;
    top: 4vw;
    left: calc(4vw + env(safe-area-inset-left));
  `)}
`;

const StyledLogoImage = styled.img`
  width: 10vw;
  min-width: 100px;
`;

const StyledMenu = styled.div`
  z-index: 9999;
  ${mediaQueries(
    null,
    'sl'
  )(css`
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 13vh;
  `)}

  ${mediaQueries(
    'sl',
    null
  )(css`
    position: absolute;
    right: calc(4vw + env(safe-area-inset-right));
    bottom: calc(4vw + env(safe-area-inset-top));
  `)}

  a, a:focus, a:hover, a:visited, a:active {
    span {
      color: #fff;
    }
  }

  > span {
    display: inline-block;
    padding: 0 0.7vw;

    ${mediaQueries(
      null,
      'sl'
    )(css`
      display: block;
    `)}
  }
`;

const StyledMainText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: calc(17px + 2vw + 2vh);
  line-height: 80px;

  ${mediaQueries(
    null,
    'sl'
  )(css`
    width: 70vw;
  `)}

  ${mediaQueries(
    'sl',
    null
  )(css`
    width: 80vw;
  `)}
`;

const VideoCover: React.FC = () => {
  const videoRef = React.createRef<HTMLVideoElement>();
  const [manualPlay, setManualPlay] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  let timeout;

  useEffect(() => {
    // Check if timeout is running
    if (timeout) {
      // Clear it
      clearTimeout(timeout);
      timeout = null;
    }
    // Start the timeout of 1 second
    timeout = setTimeout(() => {
      // Check if the video started playing after a second
      if (videoRef.current && videoRef.current.paused && !manualPlay) {
        // Try to play the video
        tryPlayVideo();
        // Remove timeout
        timeout = null;
      }
    }, 1000);
  }, [videoRef]);

  const tryPlayVideo = () => {
    // Show the play button if the autoplay
    // failed
    videoRef.current
      .play()
      .then(() => {
        setIsPlaying(true);
        setManualPlay(false);
      })
      .catch(() => {
        setIsPlaying(false);
        setManualPlay(true);
      });
  };

  const handleCoverClick = () => {
    // Check if the video ref exists
    if (videoRef.current) {
      // Check if video is paused or hasn't started playign
      if (videoRef.current.currentTime === 0 || videoRef.current.paused) {
        // Play
        tryPlayVideo();
        // Remove the manual play
        setManualPlay(false);
      } else {
        // Pause otherwise
        videoRef.current.pause();
        setManualPlay(true);
      }
    }
  };

  return (
    <StyledVideoCover onClick={handleCoverClick}>
      <StyledBackDrop>
        <Button hidden={!manualPlay} icon='play' />
        <StyledLogo>
          <Fade left cascade delay={500}>
            <StyledLogoImage src={whiteLogo} />
          </Fade>
          <Fade left cascade delay={750}>
            <span>dekoratívna dielňa</span>
          </Fade>
        </StyledLogo>
        <StyledMainText>
          <Fade delay={500}>Dekorácie vyrobené u nás s láskou pre Vás.</Fade>
        </StyledMainText>
        <StyledMenu>
          <Fade right cascade delay={1250}>
            <a href='#contact'>kontakt</a>
          </Fade>
        </StyledMenu>
      </StyledBackDrop>
      {!isPlaying && <img src={bgvideoPhoto} className='bgplaceholder' />}
      <video
        style={{ display: isPlaying ? 'unset' : 'none' }}
        src={bgvideo}
        onCanPlay={() => tryPlayVideo()}
        playsInline
        loop
        muted
        ref={videoRef}
      />
    </StyledVideoCover>
  );
};

export default VideoCover;

import React from 'react';
import styled, { css } from 'styled-components';
import { isIPad13 } from 'react-device-detect';
import { mediaQueries } from '../helpers/styles';
import { Colors } from '../constants/colors';
import Fade from 'react-reveal/Fade';

const StyledSection = styled('div')<ISectionProps>`
  width: 100%;
  min-height: ${({ height }) => height || '100vh'};
  display: flex;
  position: relative;

  ${mediaQueries(
    null,
    'sl'
  )(css`
    flex-flow: column;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    flex-flow: row;
  `)}
  
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  ${({ skew }) =>
    skew &&
    css`
      transform: skew(0, 5deg);
      > * {
        transform: skew(0, -5deg);
      }
    `};

  ${({ gradient }) =>
    gradient &&
    css`
      background-image: linear-gradient(
        to ${gradient.to},
        ${gradient.start},
        ${gradient.end}
      );
    `};

  ${({ bgImage }) =>
    bgImage &&
    css`
      ${mediaQueries(
        null,
        'sl'
      )(css`
        background-image: url(${bgImage.vertical});
      `)}
      ${mediaQueries(
        'sl',
        null
      )(css`
        background-image: url(${bgImage.horizontal});
      `)}
      
      ${mediaQueries(
        'ml',
        null
      )(css`
        background-attachment: ${isIPad13 ? 'none' : 'fixed'};
      `)}
      background-size: cover;
      background-position: center center;
    `}

  ${({ hasPadding }) =>
    hasPadding &&
    css`
      ${mediaQueries(
        null,
        'sl'
      )(css`
        padding: calc(6vw + env(safe-area-inset-right));
      `)}
      ${mediaQueries(
        'sl',
        null
      )(css`
        padding: calc(
          // @ts-ignore
            ${({ smallPadding }) => (smallPadding ? '1vw' : '10vw')} +
            env(safe-area-inset-right)
        );
      `)}
    `}
`;

export interface ISectionProps {
  hasPadding?: boolean;
  height?: string;
  children?: React.ReactNode;
  bgImage?: {
    horizontal: string;
    vertical: string;
  };
  skew?: {
    topColor: string;
    bottomColor: string;
  };
  bgColor?: string;
  gradient?: {
    to: string;
    start: string;
    end: string;
  };
  link?: string;
  linkColor?: string;
  id?: string;
  hasShadow?: boolean;
  smallPadding?: boolean;
}

const StyledSkewWrapper = styled('div')<ISectionProps>`
  width: 100%;
  min-height: calc(100vh + calc(100vw / 23) * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;

  ${({ skew }) => css`
    background-image: linear-gradient(${skew.topColor}, ${skew.bottomColor});
  `}
`;

const StyledLink = styled.a<ISectionProps>`
  display: block;
  color: ${({ color }) => color || Colors.PRIMARY_GREEN};
  text-align: center;
  background-color: transparent;
  text-decoration: none;
  font-size: 20px;
  opacity: 0.3;

  &:active,
  &:visited,
  &:focus {
    color: ${({ color }) => color || Colors.PRIMARY_GREEN};
    text-decoration: none;
  }

  ${mediaQueries(
    null,
    'sl'
  )(css`
    line-height: 50px;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  `)}
`;

const Section: React.FC<ISectionProps> = ({
  children,
  link,
  linkColor,
  ...rest
}) => {
  return rest.skew ? (
    <>
      <StyledSkewWrapper skew={rest.skew}>
        <StyledSection {...rest}>
          {children}
          {link && (
            <StyledLink href={`#${link}`} color={linkColor}>
              <Fade fraction={1}>#</Fade>
            </StyledLink>
          )}
        </StyledSection>
      </StyledSkewWrapper>
    </>
  ) : (
    <StyledSection {...rest}>
      {children}
      {link && (
        <StyledLink href={`#${link}`} color={linkColor}>
          <Fade fraction={1}>#</Fade>
        </StyledLink>
      )}
    </StyledSection>
  );
};

export default Section;

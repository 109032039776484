import React, { useState, useEffect, useRef } from 'react';
import { Reset } from 'styled-reset';
import styled, { css } from 'styled-components';
import VideoCover from './components/VideoCover';
import Section from './components/Section';
import Fade from 'react-reveal/Fade';
import { Colors } from './constants/colors';
import bgImage from './assets/products_dark.jpg';
import products from './assets/products.jpg';
import bgImageVertical from './assets/products_vertical_dark.jpg';
import logoM from './assets/logo_m.png';
import logoI from './assets/logo_i.png';
import logoL from './assets/logo_l.png';
import logoO from './assets/logo_o.png';
import venec from './assets/venec.jpg';
import bowl from './assets/bowl.jpg';
import bowl2 from './assets/bowl2.jpg';
import obraz from './assets/obraz.jpg';
import candle from './assets/candle.jpg';
import venec2 from './assets/venec2.jpg';
import logoGreen from './assets/logo_green.png';
import SectionItem from './components/SectionItem';
import { mediaQueries } from './helpers/styles';
import Separator from './components/Separator';
import Button from './components/Button';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import { MdMailOutline, MdPhone, MdInfoOutline } from 'react-icons/md';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import { IoIosFlower } from 'react-icons/io';
import { AiOutlinePicture } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import Testimonial from './components/Testimonial';
import { GiFlowerPot, GiBerriesBowl } from 'react-icons/gi';
import { isMobileOnly, isMobile } from 'react-device-detect';

const StyledAppWrapper = styled('div')<{}>`
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition: height 0.2s linear;

  font-size: calc(20px + 0.5vw);
  line-height: calc(22px + 2vw);

  a,
  a:active,
  a:visited,
  a:focus {
    transition: all 0.2s ease-out;
    color: ${Colors.PRIMARY_GREEN};

    &:hover {
      color: ${Colors.PRIMARY_GREEN_DARKER};
    }
  }

  ${mediaQueries(
    null,
    'sl'
  )(css`
    font-weight: 200;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    font-weight: 100;
  `)}

  * {
    box-sizing: border-box;
  }
`;

const StyledSectionHeader = styled('h3')<{ white?: boolean }>`
  font-family: 'Dancing Script', cursive;
  font-size: calc(35px + 0.5vw);
  line-height: calc(38px + 2vw);
  color: ${({ white }) => (white ? '#fff' : Colors.PRIMARY_GREEN)};
  text-align: center;

  ${mediaQueries(
    null,
    'sl'
  )(css`
    font-weight: 300;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    font-weight: 200;
  `)}

  * {
    box-sizing: border-box;
  }
`;

const StyledAutograph = styled.span`
  font-size: calc(13px + 0.5vw);
  line-height: calc(13px + 2vw);
  color: #fff;

  a,
  a:active,
  a:visited,
  a:focus {
    transition: all 0.2s ease-out;
    color: #fff;

    &:hover {
      color: #eee;
    }
  }
`;

export const StyledSectionText = styled('p')<any>`
  font-size: calc(${({ small }) => (small ? '15px' : '15px')} + 0.5vw);
  color: ${({ white }) => (white ? '#fff' : Colors.PRIMARY_DARK)};

  ${mediaQueries(
    null,
    'sl'
  )(css`
    text-align: justify;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    text-align: center;
  `)}
  svg {
    vertical-align: middle;
  }
`;

const StyledSectionImg = styled.img`
  ${mediaQueries(
    null,
    'sl'
  )(
    css`
      margin: 0 auto;
    `
  )};
  ${mediaQueries(
    'sl',
    null
  )(
    css`
      margin: 0 auto;
    `
  )};
  border: calc(5px + 0.5vw) solid #eee;
  display: block;
  width: 70%;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 0px 6px 4px;
`;

const StyledImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  cursor: pointer;

  .react-reveal {
  }

  .react-reveal img {
    transition: all 1s ease-in-out;
  }

  .react-reveal:first-child img {
    ${mediaQueries(
      null,
      'sl'
    )(
      css`
        margin-top: 60px;
      `
    )};
    ${mediaQueries(
      'sl',
      null
    )(
      css`
        margin-left: 25%;
      `
    )};
    transform: scale(0.9);
  }

  .react-reveal:not(:first-child):not(:last-child) img {
    ${mediaQueries(
      null,
      'sl'
    )(
      css`
        margin-top: 30px;
      `
    )};
    ${mediaQueries(
      'sl',
      null
    )(
      css`
        margin-left: 20%;
      `
    )};
    transform: scale(0.95);
  }

  .react-reveal:last-child img {
    ${mediaQueries(
      'sl',
      null
    )(
      css`
        margin-left: 50%;
        transform: translateX(-50%);
      `
    )};
  }

  &.images-revealed {
    .react-reveal:first-child img {
      ${mediaQueries(
        null,
        's'
      )(
        css`
          margin-top: 100%;
        `
      )};
      ${mediaQueries(
        's',
        'm'
      )(
        css`
          margin-top: 150%;
        `
      )};
      ${mediaQueries(
        'm',
        null
      )(
        css`
          margin-left: -140%;
        `
      )};
      transform: scale(1);
    }

    .react-reveal:not(:first-child):not(:last-child) img {
      ${mediaQueries(
        null,
        's'
      )(
        css`
          margin-top: 0;
        `
      )};
      ${mediaQueries(
        's',
        'm'
      )(
        css`
          margin-top: 50%;
        `
      )};
      ${mediaQueries(
        'm',
        null
      )(
        css`
          margin-left: -50%;
        `
      )};
      transform: scale(1);
    }

    .react-reveal:last-child img {
      ${mediaQueries(
        null,
        's'
      )(
        css`
          margin-top: -100%;
        `
      )};
      ${mediaQueries(
        's',
        'm'
      )(
        css`
          margin-top: -50%;
        `
      )};
      ${mediaQueries(
        'sl',
        null
      )(
        css`
          margin-left: 75%;
        `
      )};
    }
  }

  .react-reveal {
    grid-row-start: 1;
    grid-column-start: 1;
  }
`;

const StyledMapWrapper = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 0px 6px 4px;
  }
`;

const StyledTestimonialRow = styled.div`
  display: flex;

  ${mediaQueries(
    null,
    'sl'
  )(css`
    flex-flow: column;
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    flex-flow: row;
  `)}
`;

const StyledLogoWrapper = styled.div`
  position: relative;

  .react-reveal {
    display: inline-block;
  }
`;

const StyledLogo = styled('img')<any>`
  width: ${({ width }) => width};
  display: inline-block;
  margin: ${({ margin }) => margin || '0 1vw'};
`;

const StyledMissionWrapper = styled('div')`
  opacity: 1;
  transition: opacity 0.8s ease-out;

  &.hidden {
    opacity: 0;
  }
`;

export const Spacer = styled.div`
  padding: 2vh 0;
`;

const StyledProductsWrapper = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  flex-flow: column;

  ${mediaQueries(
    null,
    'sl'
  )(css`
    .react-reveal {
      height: 100%;
      width: calc(100% - 20px);
      margin: 10px;

      &:last-child {
        margin-top: 0;
      }
    }
  `)}
  ${mediaQueries(
    'sl',
    null
  )(css`
    .react-reveal {
      height: 50%;
      margin: 10px;
      width: calc(100% - 20px);
    }
    &.left {
      div:first-child {
        margin-right: 0;
        margin-bottom: 0;
        width: calc(100% - 10px);
      }
      div:last-child {
        margin-right: 0;
        width: calc(100% - 10px);
      }
    }
    &.right {
      .react-reveal {
        height: calc(100% - 20px);
      }
    }
  `)}
`;

const StyledProductImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 5px;
`;

const App: React.FC = () => {
  const [showImages, setShowImages] = useState<boolean>(false);

  return (
    <>
      <ReactSEOMetaTags
        website={{
          title: 'Dekoratívna dielňa Milo',
          description:
            'Dekoratívna dielňa Milo tvorená rodinným tímom, ktorá Vám zútulní a skrášli Váš interier/exterier. Zaoberáme sa dekoráciami do interieru, exterieru, výsadbou kvetov, drevin a sezónnymi dekoráciami',
          url: 'https://dekoracie-milo.sk',
          language: 'sk_SK',
          image: products
        }}
      />
      <Reset />
      <IconContext.Provider
        value={{ size: '19px', color: Colors.PRIMARY_GREEN }}
      >
        <StyledAppWrapper>
          <VideoCover />
          <Section
            id='aboutus'
            hasPadding
            gradient={{
              to: 'bottom',
              start: '#fff',
              end: '#eee'
            }}
            link='aboutus'
          >
            <SectionItem>
              <div>
                <StyledSectionHeader>
                  <Fade top delay={150} fraction={1}>
                    Náš príbeh
                  </Fade>
                </StyledSectionHeader>
                <Separator />
                <StyledSectionText>
                  <Fade bottom delay={500} fraction={0.35}>
                    Sme manželský pár, ktorý si jedného dňa povedal, že chce
                    robiť niečo, čo ho bude napĺnať, tešiť a bude svojou prácou
                    robiť radosť ľuďom. Absolvovali sme floristickú školu
                    ukončenú štátnou skùškou v Českej Republike, začali na sebe
                    pracovať a zdokonalovať sa. Čím viac sme sa vzdelávali, tým
                    viac rástla vášeň k práci.
                  </Fade>
                </StyledSectionText>
                <Spacer />
                <StyledSectionText>
                  <Fade bottom delay={500} fraction={0.35}>
                    Otvorili sme si vlastnú dekoratívnu dielňu, kde môžete nájsť
                    našu tvorbu. Ak ste ešte nemali možnosť nás navštíviť, tak
                    dúfame, že po pozretí tohto webu, budete mať chuť sa prísť
                    pozrieť osobne na dekorácie a doplnky, ktoré tvoríme pre
                    Vás.
                  </Fade>
                </StyledSectionText>
              </div>
            </SectionItem>
          </Section>
          <Section
            id='mission'
            hasPadding
            skew={{
              topColor: '#eee',
              bottomColor: '#fff'
            }}
            gradient={{
              to: 'right bottom',
              start: Colors.PRIMARY_GREEN_LIGHTER,
              end: Colors.PRIMARY_GREEN_DARKER
            }}
            link='mission'
            linkColor='#fff'
          >
            <SectionItem>
              <StyledMissionWrapper className={showImages ? 'hidden' : ''}>
                <StyledSectionHeader white>
                  <Fade top delay={150} fraction={1}>
                    Naša vízia
                  </Fade>
                </StyledSectionHeader>
                <Separator white />
                <StyledSectionText white>
                  <Fade left delay={500} fraction={0.35}>
                    Napredovať, učiť sa nové techniky, byť vždy trendy v svojom
                    obore a hlavne tvoriť tak, aby ste boli spokojní Vy a
                    odchádzali s produktom, ktorý bude presne podľa Vaších
                    predstáv.
                  </Fade>
                </StyledSectionText>
              </StyledMissionWrapper>
            </SectionItem>
            <SectionItem>
              <div>
                <StyledImagesWrapper
                  className={showImages ? 'images-revealed' : undefined}
                  onClick={() => {
                    setShowImages(!showImages);
                  }}
                >
                  <Fade right delay={900} fraction={0.5}>
                    <StyledSectionImg src={venec} />
                  </Fade>
                  <Fade right delay={1100} fraction={0.5}>
                    <StyledSectionImg src={candle} />
                  </Fade>
                  <Fade right delay={1300} fraction={0.5}>
                    <StyledSectionImg src={obraz} />
                  </Fade>
                </StyledImagesWrapper>
                <Button
                  hidden={showImages}
                  onClick={() => {
                    setShowImages(!showImages);
                  }}
                />
              </div>
            </SectionItem>
          </Section>
          <Section
            id='ourwork'
            hasPadding
            link='ourwork'
            gradient={{
              to: 'bottom',
              start: '#fff',
              end: '#eee'
            }}
          >
            <SectionItem>
              <div>
                <StyledSectionHeader>
                  <Fade top delay={150} fraction={1}>
                    Naša tvorba
                  </Fade>
                </StyledSectionHeader>
                <Separator />
                <StyledTestimonialRow>
                  <Testimonial
                    username='Dekorácie'
                    text='Dekorácie do interiérov aj exteriérov prevažne z prírodného materiálu, dreva, skla a ďalších.'
                    delay={500}
                    titleTop
                    Icon={GiBerriesBowl}
                  />
                  <Testimonial
                    username='Výsadba'
                    text='Výsadby do interiérov aj exteriérov - trvalky, dreviny, sukulenty, kaktusy a iné.'
                    delay={700}
                    titleTop
                    Icon={GiFlowerPot}
                  />
                  <Testimonial
                    username='Obrazy'
                    text='Obrazy tvoríme z prírodných materiálov vo viacerých formátoch podľa výberu.'
                    delay={900}
                    titleTop
                    Icon={AiOutlinePicture}
                  />
                </StyledTestimonialRow>
                <Spacer />
                <Fade bottom delay={300} fraction={0.5}>
                  <StyledSectionText>
                    <MdInfoOutline /> Objednávky podľa vlastného výberu osobne u
                    nás v dielni alebo po tel./emailovej dohode.
                  </StyledSectionText>
                </Fade>
              </div>
            </SectionItem>
          </Section>
          <Section bgColor='#eee'>
            <StyledProductsWrapper className='left'>
              <Fade left delay={150} fraction={0.5}>
                <StyledProductImage src={bowl} />
              </Fade>
              <Fade left delay={350} fraction={0.5}>
                <StyledProductImage src={bowl2} />
              </Fade>
            </StyledProductsWrapper>
            <StyledProductsWrapper className='right'>
              <Fade right delay={550} fraction={0.2}>
                <StyledProductImage src={venec2} />
              </Fade>
            </StyledProductsWrapper>
          </Section>
          <Section bgColor='#eee' smallPadding hasPadding height='50px'>
            <SectionItem padding='10px'>
              <Fade left delay={200} fraction={0.5}>
                <StyledSectionText>
                  <MdInfoOutline /> Ďalšie ukážky našej tvorby nájdete na{' '}
                  <a
                    href='https://instagram.com/milo_dekoracie'
                    target='_blank'
                  >
                    @milo_dekoracie
                  </a>
                </StyledSectionText>
              </Fade>
            </SectionItem>
          </Section>
          <Section
            id='testimonials'
            hasPadding
            link='testimonials'
            gradient={{
              to: 'bottom',
              start: '#eee',
              end: '#fff'
            }}
          >
            <SectionItem>
              <div>
                <StyledSectionHeader>
                  <Fade top delay={150} fraction={1}>
                    Čo o nás hovoria
                  </Fade>
                </StyledSectionHeader>
                <Separator />
                <StyledTestimonialRow>
                  <Testimonial
                    username='Michaela'
                    text='"Prišla som si k Vám pre dekoráciu na objednávku a keď
                          som videla tie obrazy, tak som nemohla
                          odísť bez toho, aby som si jeden nezobrala."'
                    delay={500}
                  />

                  <Testimonial
                    username='Petra'
                    text='"Manžel sa naučil k Vám chodiť pre kvety na moje narodeniny, ktoré sadíte do skla namiesto kytíc, pretože mi vydržia a mám z nich celý rok radosť."'
                    delay={700}
                  />
                  <Testimonial
                    username='Oliver'
                    text='"Vždy skvelý výber produktov, príjemné prostredie a úžasná energia. Páči sa mi, že si tvoríte vlastné kolekcie. Vždy nájdem niečo, co mi padne do oka"'
                    delay={900}
                  />
                </StyledTestimonialRow>
                <StyledTestimonialRow>
                  <Testimonial
                    username='Janka'
                    text='"Konečne som našla v Žiline miesto, kde pre mňa vytvoria dekorácie podľa mojej predstavy. Som veľmi spokojná."'
                    delay={500}
                  />
                  <Testimonial
                    username='Ondrej'
                    text='"Manželkine slová po prvej návšteve u Vás boli, že sa cíti ako keby prišla z terapie pre dušu. Zbožnuje Vašu tvorbu. Vkusná a jednoduchá."'
                    delay={700}
                  />
                  <Testimonial
                    username='Alexandra'
                    text='"Milo ma potešilo Vaše originálne balenie produktov, ktoré som si domov priniesla v TOP stave bez poškodenia. Ste veľmi precízni. "'
                    delay={900}
                  />
                </StyledTestimonialRow>
              </div>
            </SectionItem>
          </Section>
          <Section
            bgImage={{
              horizontal: bgImage,
              vertical: bgImageVertical
            }}
            hasShadow
          >
            <SectionItem>
              <div>
                <StyledLogoWrapper>
                  <Fade left delay={400} fraction={1}>
                    <StyledLogo src={logoM} width='14vw' />
                  </Fade>
                  <Fade top delay={700} fraction={1}>
                    <StyledLogo
                      src={logoI}
                      width='4.04vw'
                      margin='0 1vw 0 -0.5vw'
                    />
                  </Fade>
                  <Fade bottom delay={1000} fraction={1}>
                    <StyledLogo src={logoL} width='2vw' />
                  </Fade>
                  <Fade right delay={1300} fraction={1}>
                    <StyledLogo src={logoO} width='8vw' />
                  </Fade>
                </StyledLogoWrapper>
              </div>
            </SectionItem>
          </Section>
          <Section
            id='contact'
            gradient={{
              to: 'left bottom',
              start: '#eee',
              end: '#fff'
            }}
            hasPadding
            link='contact'
          >
            <SectionItem>
              <div>
                <Fade top delay={150} fraction={1}>
                  <StyledSectionHeader>Kontaktujte nás</StyledSectionHeader>
                </Fade>
                <Separator />
                <Fade left delay={500} fraction={0.35}>
                  <StyledSectionText>
                    Ak by Vás niečo zaujímalo a nenašli ste na našom webe
                    odpoveď alebo by ste mali záujem si nejaký produkt objednať
                    či už z aktuálneho výberu alebo podľa Vaších predstáv,
                    môžete nás kontaktovať prostredníctvom:
                  </StyledSectionText>
                  <Spacer />
                  <StyledSectionText>
                    <MdPhone /> +421 907 228 558
                  </StyledSectionText>
                  <StyledSectionText>
                    <MdMailOutline />{' '}
                    <a href='mailto:milova.iveta@gmail.com'>
                      milova.iveta@gmail.com
                    </a>
                  </StyledSectionText>
                  <Spacer />
                  <StyledSectionText>
                    Aktuálnu tvorbu môžete sledovať prostredníctvom:
                  </StyledSectionText>
                  <Spacer />
                  <StyledSectionText>
                    <FaInstagram />{' '}
                    <a
                      href='https://instagram.com/milo_dekoracie'
                      target='_blank'
                    >
                      @milo_dekoracie
                    </a>
                  </StyledSectionText>
                  <StyledSectionText>
                    <FaFacebook />{' '}
                    <a
                      href='https://www.facebook.com/dekoraciemilo'
                      target='_blank'
                    >
                      Dekoratívna dielňa Milo
                    </a>
                  </StyledSectionText>
                  <Spacer />
                  <StyledSectionText>Tešíme sa na Vás.</StyledSectionText>
                  <StyledSectionText>
                    <img src={logoGreen} style={{ width: '50px' }} />
                  </StyledSectionText>
                </Fade>
              </div>
            </SectionItem>
            {isMobile && <Spacer />}
            <SectionItem>
              <StyledMapWrapper>
                <Fade
                  right
                  delay={1000}
                  fraction={0.5}
                  style={{ width: '100%', height: '100%' }}
                >
                  <iframe
                    width='100%'
                    height='100%'
                    id='gmap_canvas'
                    src='https://maps.google.com/maps?q=1.%20m%C3%A1ja%20896%2F17%2C%20010%2001%20%C5%BDilina&t=&z=15&ie=UTF8&iwloc=&output=embed'
                    frameBorder='0'
                    scrolling='no'
                  ></iframe>
                </Fade>
              </StyledMapWrapper>
            </SectionItem>
          </Section>
          <Section
            height='10px'
            gradient={{
              to: 'bottom',
              start: Colors.PRIMARY_GREEN,
              end: Colors.PRIMARY_GREEN_DARKER
            }}
          >
            <SectionItem padding='0.5vw'>
              <StyledAutograph>
                Made with ❤️ by{' '}
                <a href='https://twitter.com/foxhoundn' target='_blank'>
                  @foxhoundn
                </a>
              </StyledAutograph>
            </SectionItem>
          </Section>
        </StyledAppWrapper>
      </IconContext.Provider>
    </>
  );
};

export default App;

import mq from 'styled-components-media-query';
// Save the break points for different sizes / screens
const bp: { [size: string]: number } = {
  s: 600,
  sl: 800,
  m: 800,
  ml: 992,
  l: 1100
};
// Export the media queries
// @ts-ignore
export const mediaQueries: any = mq({ bp });

import React, { memo } from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { GiClick } from 'react-icons/gi';
import { TiMediaPlay } from 'react-icons/ti';
import { Colors } from '../constants/colors';
import Fade from 'react-reveal/Fade';

const StyledButtonWrapper = styled.div<{ hidden?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.3s ease-in-out;
  opacity: 1;
  cursor: pointer;

  &.hidden {
    opacity: 0;
  }
`;

const StyledButton = styled.button`
  width: calc(22px + 0.5vw);
  height: calc(22px + 0.5vw);
  border-radius: 99px;
  background-color: #fff;
  text-align: center;
  transform: scale(1);
  animation-name: click;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: calc(20px + 0.5vw);
    width: calc(20px + 0.5vw);
    border-radius: 99px;
    border: 1px solid #fff;
    top: -1px;
    left: -1px;
    opacity: 0;
    animation-name: spread;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  @keyframes spread {
    0% {
      transform: scale(1);
      opacity: 0;
    }

    30% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  @keyframes click {
    0% {
      transform: scale(1);
    }

    30% {
      transform: scale(0.9);
    }

    35% {
      transform: scale(0.7);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export interface IButtonProps {
  icon?: string;
  onClick?: any;
  hidden?: boolean;
  fadeDelay?: number;
}

const Button: React.FC<IButtonProps> = ({
  onClick,
  hidden,
  fadeDelay = 1300,
  icon
}) => (
  <StyledButtonWrapper className={hidden ? 'hidden' : ''}>
    <Fade delay={fadeDelay}>
      <StyledButton onClick={onClick}>
        <IconContext.Provider
          value={{
            size: 'calc(11px + 0.5vw)',
            color: Colors.PRIMARY_GREEN,
            style: {
              margin: '0 auto',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
              verticalAlign: 'middle'
            }
          }}
        >
          {icon === 'play' ? <TiMediaPlay /> : <GiClick />}
        </IconContext.Provider>
      </StyledButton>
    </Fade>
  </StyledButtonWrapper>
);

export default memo(Button);

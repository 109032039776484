import React from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';
import greenLeaves from '../assets/green_leaves.png';
import whiteLeaves from '../assets/white_leaves.png';
import { Colors } from '../constants/colors';

export interface ISeparatorProps {
  white?: boolean;
  simple?: boolean;
}

const StyledSeparator = styled.div<{ simple?: boolean }>`
  height: ${({ simple }) => (simple ? '40px' : '80px')};
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  opacity: 0.5;

  .react-reveal:first-child,
  .react-reveal:last-child {
    flex: 1;
  }
`;

const StyledSeparatorImage = styled.img`
  width: 2.5vw;
  min-width: 25px;
  max-width: 50px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 20px;
`;

const StyledSeparatorLine = styled('hr')<{ side?: string; white?: boolean }>`
  border-top: ${({ white }) =>
    css`1px solid ${white ? '#fff' : Colors.PRIMARY_GREEN_LIGHTER}`};
  border-bottom: 0;
  ${({ side }) =>
    side &&
    css`
    margin-${side}: 10%;
  `}
  flex: 1;
`;

const Separator: React.FC<ISeparatorProps> = ({ white, simple }) => (
  <StyledSeparator simple={simple}>
    <Fade left delay={1000} style={{ flex: 1 }} fraction={1}>
      <StyledSeparatorLine white={white} side={!simple && 'left'} />
    </Fade>
    {!simple && (
      <>
        <Fade delay={1000} style={{ flex: 1 }} fraction={1}>
          <StyledSeparatorImage src={white ? whiteLeaves : greenLeaves} />
        </Fade>
        <Fade right delay={1000} style={{ flex: 1 }} fraction={1}>
          <StyledSeparatorLine white={white} side='right' />
        </Fade>
      </>
    )}
  </StyledSeparator>
);

export default Separator;

import React from 'react';
import SectionItem from './SectionItem';
import { StyledSectionText, Spacer } from '../App';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { MdPerson } from 'react-icons/md';
import { IconContext, IconType } from 'react-icons';
import { Colors } from '../constants/colors';

const StyledTestimonialUser = styled.p`
  font-weight: 300;

  svg {
    margin-right: 3px;
  }
`;

export interface ITestimonialProps {
  text: string;
  username: string;
  delay: number;
  titleTop?: boolean;
  Icon?: IconType;
}

const Testimonial: React.FC<ITestimonialProps> = ({
  text,
  username,
  delay,
  titleTop,
  Icon = MdPerson
}) => (
  <IconContext.Provider value={{ size: '15px', color: Colors.PRIMARY_GREEN }}>
    <SectionItem padding='1vw'>
      <div>
        <StyledSectionText small>
          {titleTop && (
            <>
              <Fade top delay={delay + 50} fraction={0.1}>
                <StyledTestimonialUser>
                  <Icon /> {username}
                </StyledTestimonialUser>
              </Fade>
              <Spacer />
            </>
          )}
          <Fade bottom={titleTop} top={!titleTop} delay={delay} fraction={1}>
            {text}
          </Fade>
          {!titleTop && (
            <Fade bottom delay={delay + 50} fraction={0.1}>
              <StyledTestimonialUser>
                <Icon /> {username}
              </StyledTestimonialUser>
            </Fade>
          )}
        </StyledSectionText>
      </div>
    </SectionItem>
  </IconContext.Provider>
);

export default Testimonial;
